const authProvider = {
    login: async ({ username, password }) => {
        const opts = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            body: 'username=' + username + '&password=' + password,
            mode: 'cors'
        }
        const response = await fetch(window.env.API_URL_ARC + '/mentos-admin/authenticate', opts);
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
        }
        const authenticationInfo = await response.json();
        localStorage.setItem('token', authenticationInfo.access_token)
        const userInfo = authenticationInfo.user_info;
        const user = userInfo ? (userInfo.name ? userInfo.name : userInfo.email) : ''
        localStorage.setItem('user', user)
        return Promise.resolve()
    },

    logout: params => {
        localStorage.clear()
        return Promise.resolve()
    },

    checkAuth: params =>
        localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject(),

    checkError: (error) => {
        let status = error.status
        if (status === 401 || status === 403) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            return Promise.reject()
        }
        return Promise.resolve()
    },

    getPermissions: params => Promise.resolve(),

}

export default authProvider;
