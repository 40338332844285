import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import QuizPreviewUrlField from './QuizPreviewUrlField';

const RedirectFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput
      label="Kvissnavn"
      source="quizName"
      defaultValue="Hva leter du etter?"
    />
  </Filter>
);

export const QuizList = (props) => (
  <List {...props} filters={<RedirectFilter />}>
    <Datagrid rowClick="edit">
      <TextField label="Kvissnavn" source="quizName" />
      <TextField label="Slugnavn" source="slugName" />
      <TextField label="Kvissmester(-e)" source="authors" />
      <QuizPreviewUrlField label="Preview Url" source="quizName" />
      <BooleanField label="default Kviss" source="defaultQuiz" />
      <EditButton />
    </Datagrid>
  </List>
);

export default QuizList;
