import * as React from 'react';

const QuizPreviewUrlField = ({ record = {}, source }) => {
  var slugName = record.slugName;
  let url = window.env.QUIZ_URL;

  return (
    <a href={url + '/' + slugName} onClick="return false">
      {record[source]}
    </a>
  );
};

export default QuizPreviewUrlField;
