import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Edit,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const QuizEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput
        label="Default Kviss"
        variant="standard"
        source="defaultQuiz"
      />
      <TextField
        label="Kvissnavn"
        source="QuizName"
        fullWidth
        variant="standard"
        validate={[required()]}
      />
      <TextField
        label="Slugnavn"
        source="slugName"
        fullWidth
        variant="standard"
        validate={[required()]}
      />
      <TextInput
        label="Kvissmester(-e)"
        source="authors"
        fullWidth
        variant="standard"
        validate={[required()]}
      />
      <ArrayInput label="Spørsmål" source="adjacencyPairs" variant="standard">
        <SimpleFormIterator>
          <RichTextInput
            label="Spørsmål"
            source="question"
            fullWidth
            validate={[required()]}
          />
          <RichTextInput label="Svar" source="answer" />
          <TextInput label="Bildeadressen" source="imageUrl" fullWidth />
          <TextInput label="Alt tekst" source="imageAltText" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default QuizEdit;

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
