import React from 'react';
import { HttpError } from 'react-admin';
import simpleRestProvider from './DataRestProvider';


const fetchJson = async (url, options = {}) => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: 'application/json'
        })
    );
    requestHeaders.set('Content-Type', 'application/json');
    const token = localStorage.getItem('token');
    requestHeaders.set('Authorization', "Bearer " + token);
    const response = await fetch(url, { ...options, headers: requestHeaders })
    const text = await response.text()
    const o = {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
    };
    let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
    let json;
    try {
        json = JSON.parse(body);
    } catch (e) {
        // not json, no big deal
    }
    if (status < 200 || status >= 300) {
        if (json && json.error_message) return Promise.reject(new HttpError(json.error_message))
        return Promise.reject(new HttpError(statusText, status, json))
    }
    return Promise.resolve({ status: status, headers: headers, body: body, json: json })
};

const httpClient = (url, options = {}) => {
    return fetchJson(url, options);
};


const dataProviders = [
    { dataProvider: simpleRestProvider(window.env.QUIZ_API_URL + '/admin', httpClient), resources: ['quiz'] },
];


export default function dataProvider(type, resource, params) {
    const dataProviderMapping = dataProviders.find(dp => dp.resources.includes(resource));
    console.log(type)
    switch (type) {
        case 'GET_LIST':
            return dataProviderMapping.dataProvider.getList(resource, params);
        case 'GET_ONE':
            return dataProviderMapping.dataProvider.getOne(resource, params);
        case 'GET_MANY':
            return dataProviderMapping.dataProvider.getMany(resource, params);
        case 'GET_MANY_REFERENCE':
            return dataProviderMapping.dataProvider.getManyReference(resource, params);
        case 'UPDATE':
            return dataProviderMapping.dataProvider.update(resource, params);
        case 'UPDATE_MANY':
            return dataProviderMapping.dataProvider.updateMany(resource, params);
        case 'CREATE':
            return dataProviderMapping.dataProvider.create(resource, params);
        case 'DELETE':
            return dataProviderMapping.dataProvider.delete(resource, params);
        case 'DELETE_MANY':
            return dataProviderMapping.dataProvider.deleteMany(resource, params);
    }
}
