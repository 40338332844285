import { Admin, createMuiTheme, Resource } from 'react-admin';
import authProvider from '../../providers/AuthenticationProvider/AuthProvider';
import dataProviders from '../../providers/Dataprovider/Dataproviders';
import Dashboard from '../dashboard/Dashboard';
import QuizCreate from '../quiz/QuizCreate';
import QuizEdit from '../quiz/QuizEdit';
import QuizList from '../quiz/QuizList';
import './App.css';

const App = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#990000',
      },
      background: { default: '#f4f0e8' },
    },
    typography: {
      fontFamily: ['Georgia'],
    },
  });

  return (
    <Admin
      dashboard={Dashboard}
      dataProvider={dataProviders}
      authProvider={authProvider}
      title="Morgenbladets Kviss-verktøy"
      theme={theme}
    >
      <Resource
        name="quiz"
        list={QuizList}
        edit={QuizEdit}
        create={QuizCreate}
      />
    </Admin>
  );
};

export default App;
