import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const QuizCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput
        label="Default Kviss"
        source="defaultQuiz"
        variant="standard"
      />
      <TextInput
        label="Kvissnavn"
        source="quizName"
        variant="standard"
        validate={[required()]}
      />
      <TextInput
        label="Slugnavn"
        source="slugName"
        fullWidth
        variant="standard"
        validate={[required()]}
      />
      <TextInput
        label="Kvissmester(-e)"
        source="authors"
        fullWidth
        variant="standard"
        validate={[required()]}
      />
      <ArrayInput label="Spørsmål" source="adjacencyPairs" variant="standard">
        <SimpleFormIterator>
          <RichTextInput
            label="Spørsmål"
            source="question"
            fullWidth
            validate={[required()]}
          />
          <RichTextInput label="Svar" source="answer" />
          <TextInput label="Bildeadressen" source="imageUrl" fullWidth />
          <TextInput label="Alt tekst" source="imageAltText" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.quizName != null) {
            formData.slugName = slugify(formData.quizName);
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default QuizCreate;

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}
