import { Box, Button, Card, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Title } from 'react-admin';
import Confetti from 'react-confetti';
import useSound from 'use-sound';
import audio from '../../assets/fanfare.mp3';
import mainLogo from '../../assets/KvissBilde.jpg';

const Dashboard = () => {
  const [confetti, setConfetti] = useState(false);
  const [playSound, exposedData] = useSound(audio);

  return (
    <>
      {confetti && <Confetti />}
      <Card
        style={{
          padding: '16px',
        }}
      >
        <Title title={'Morgenbladets kvissverktøy'} />
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              style={{ marginBotton: '8px' }}
            >
              {'1.Hvordan lager man en god kviss?'}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              style={{ marginBottom: '24px', transform: 'rotate(180deg)' }}
            >
              {'Svar: Fri assosiasjon'}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setConfetti(!confetti);
                confetti ? exposedData.stop() : playSound();
              }}
            >
              Fanfare
            </Button>
          </Box>
        </Box>
        <img src={mainLogo} />
      </Card>
    </>
  );
};

export default Dashboard;
